import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("sdcom/club/process/overview", { params });
}

export function Detail(params) {
  // console.log(parmas);
  return request.get("sys/role" + params);
}

export function Add(params) {
  return request.post("sys/role", params);
}

export function Update(params) {
  return request.put("sys/role", params);
}

export function Delete(data) {
  return request.delete("sys/role", { data });
}

export function getMedical(params) {
  // console.log(parmas);
  return request.get("tcm/medical/record/detail/", { params });
}

export function getPrescription(params) {
  // console.log(parmas);
  return request.get("tcm/medical/record/prescription/list", { params });
}