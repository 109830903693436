<template>
  <div>
    <a-modal v-model:visible="visible" title="就诊详情" @ok="handleOk" okText="新建" cancelText="关闭" :afterClose="closeModal" width="50vw" :okButtonProps="{ style: { display: 'none' }}">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">

        <div class="detailModal">
          <div><label>日期：</label><span>{{data?.date}}</span></div>
          <div><label>负责医生：</label><span>{{data?.doctorName}}</span></div>
        </div>
        <div class="detailModal line1">
          <div><label>主诉：</label><span>{{data?.chiefComplaint}}</span></div>
          <div><label>脉象：</label><span>{{data?.tcmDiagnose}}</span></div>
          <div><label>舌象：</label><span>{{data?.tongue}}</span></div>
          <div><label>中医诊断：</label><span>{{data?.fubingDiagnose}}</span></div>
          <div><label>西医诊断：</label><span>{{data?.wmDiagnose}}</span></div>
        </div>
        <div v-for="(item,i) in data2">
          <div v-if="item.category == '草药'||item.category == '颗粒剂'||item.category == '膏部'||item.category == '方部'">
            <hr />
            <div class="subtitle">处方{{i+1}}</div>
            <div class="detailModal line3" style="margin-top: 20px;">
              <div><label>炮制方法：</label><span>{{item?.processingMethod}}</span></div>
              <div><label>药量(天)：</label><span>{{item?.dosageDays}}</span></div>
              <div><label>用法：</label><span>{{item?.takingMethod}}</span></div>
            </div>
            <div class="tableStyle">
              <div class="tbody">
                <div class="tr">
                  <div class="th" v-for="item2 in item.itemList">{{item2.name}}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <hr />
            <div class="subtitle">处方{{i+1}}</div>
            <div class="tableStyle row2">
              <div class="tbody">
                <div class="tr">
                  <div class="th" v-for="item2 in item.itemList">{{item2.name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { getMedical, getPrescription } from "/src/api/consultation.js";
export default {
  components: {
  },
  data() {
    return {
      visible: this.modelValue,
      columns: [
        {
          title: "名称",
          key: "name",
          dataIndex: "name",
          width: "30%",
          slots: {
            customRender: "name"
          }
        },
        {
          title: "首字母",
          key: "nameInitial",
          dataIndex: "nameInitial",
          // ellipsis: true,
          width: "20%",
          slots: {
            customRender: "nameInitial"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: {},
      data2: [],
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {
  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    record: {
      type: Object,
      default: {}
    },
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
  },
  methods: {

    async detail() {
      let res = await getMedical({ id: this.record.medicalRecordId });
      this.data = res.data;
      let res2 = await getPrescription({ medicalRecordId: this.record.medicalRecordId, includeItems: true });
      this.data2 = res2.data;
      this.data2.forEach(item => {
        item.itemList = []
        item.items.forEach(item2 => {
          item.itemList.push({ name: item2.name })
          item.itemList.push({ name: item2.quantity })
        })
      })
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    },
  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.tableStyle {
  border: 1px solid #f0f0f0;
  padding: 10px;
}
.thead > .tr {
  background-color: #fafafa;
}
.tr {
  display: flex !important;
  flex-wrap: wrap;
  text-align: center;
  font-size: 16px;
}
.tr > div {
  width: 12.5%;
  padding: 5px 12px;
}
.row2 .tr > div:nth-child(2n + 1) {
  width: 30%;
}
.row2 .tr > div:nth-child(2n) {
  width: 70%;
  text-align-last: left;
}
.row2 .tr > div:nth-child(4n + 3),
.row2 .tr > div:nth-child(4n + 4) {
  background-color: #e6f7ff;
}
.th {
  text-align: center;
}
.th:nth-child(2n) {
  text-align: right;
  box-sizing: border-box;
  padding-right: 50px;
  border-right: 1px solid #f0f0f0;
}
.subtitle {
  text-align: left;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: cornflowerblue;
}
.detailModal label {
  text-align-last: left;
}
</style>
