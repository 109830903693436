<template>
  <div>
    <div>
      <div class="searchArea">

        <!-- <a-range-picker v-model:value="inputs.patroltime" :placeholder="['应巡开始时间','应巡结束时间']" :locale="locale" /> -->

        <a-range-picker v-model:value="date" :ranges="ranges" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" :placeholder="['开始日期','结束日期']" />

        <a-button type="primary" shape="circle" @click="search('')">
          <template #icon>
            <SearchOutlined />
          </template>
        </a-button>
        <!-- <a-button style="margin-left: 10px" @click="resetForm">重置</a-button> -->
        <a-button style="margin-left: 10px" type="primary" @click="showModal()">
          <template #icon>
            <PlusOutlined />
          </template>
          新增疾病
        </a-button>
      </div>

      <a-table class="ant-table-striped" :columns="columns" :data-source="data" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered rowKey="id" :pagination="{ pageSize:$store.state.tableSize ,total,current}" @change="search">

        <template #operation="{ record }">
          <div class="flex_sa" v-if="record.prescriptionCount>0">
            <a @click="getConsultation(record)">查看处方</a>
          </div>
        </template>
        <template #footer>
          <div class="tfoot">
            <div>合计</div>
            <div>{{customerCount}}</div>
            <div>{{medicalRecordCount}}</div>
            <div>{{totalPrescriptionCount}}</div>
            <div>{{totalMifangCount}}</div>
            <div>{{totalJingfangCount}}</div>
            <div>{{totalPayAmount}}</div>
            <div>{{consultationCount}}</div>
            <div>{{mzyCount}}</div>
          </div>
        </template>
      </a-table>
    </div>

    <getConsultation v-model="visible" :record="selectedRow" />

  </div>
</template>

<style scoped>
.online {
  background: #40a9ff;
  border-color: #40a9ff;
}

.offline {
  background: #979596;
  border-color: #979596;
}

:deep(.ant-table-tbody > tr > td, .ant-table-thead > tr > th) {
  padding: 10px 8px;
}

.table_btn {
  width: 70px;
  display: inline-block;
  text-align: center;
}
.clickable {
  /* background-color: #000; */
  width: 100%;
  height: 100%;
}

.tfoot {
  display: flex;
  width: 100%;
}

.tfoot > div {
  width: 8%;
  text-align: center;
}

.tfoot > div:nth-child(2) {
  width: 10%;
}
.tfoot > div:nth-child(4),
.tfoot > div:nth-child(5),
.tfoot > div:nth-child(6) {
  width: 6%;
}

.tfoot > div:nth-child(8) {
  width: 20%;
}
.tfoot > div:nth-child(9) {
  width: 10%;
}
:deep(.ant-table-footer) {
  padding: 16px 0;
}
</style>


<script>
import {
  SearchOutlined,
  PlusOutlined,
  DownloadOutlined,
  CheckCircleFilled,
  WarningFilled,
  FormOutlined,
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import moment from 'moment';
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { Search, Add, Update, Delete } from "/src/api/consultation.js";
import getConsultation from "/src/components/consultation/getConsultation.vue";
export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    DownloadOutlined,
    locale,
    CheckCircleFilled,
    WarningFilled,
    FormOutlined,
    getConsultation
  },
  name: "就诊一览",
  data() {
    return {
      modalBtn: "",
      modalIndex: "0",
      visible: false,
      active: undefined,
      id: undefined,
      date: [moment(), moment()],
      selectedRow: {},
      total: 0,
      current: 1,
      // pageSize: 10,
      inputs: {
        name: undefined,
        gender: undefined,
        isStandard: undefined,
      },
      ranges: {
        "今天": [moment(), moment()],
        "昨天": [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        "近7天": [moment().subtract(6, 'days'), moment()],
        "近30天": [moment().subtract(29, 'days'), moment()],
      },
      modelInput: {
        name: undefined,
        gender: undefined,
        nameInitial: undefined,
        isStandard: true,
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      option: [{ value: "黄浦区" }, { value: "浦东新区" }, { value: "长宁区" }],
      columns: [
        {
          title: "客户姓名",
          key: "name",
          dataIndex: "name",
          width: "8%",
          slots: {
            customRender: "name"
          }
        },
        {
          title: "电话",
          key: "mobile",
          dataIndex: "mobile",
          ellipsis: true,
          width: "10%",
          slots: {
            customRender: "mobile"
          },
        },
        {
          title: "看诊医生",
          width: "8%",
          key: "doctorName",
          dataIndex: "doctorName",
          slots: {
            customRender: "doctorName"
          }
        },
        {
          title: "处方数",
          key: "prescriptionCount",
          dataIndex: "prescriptionCount",
          // ellipsis: true,
          width: "6%",
          slots: {
            customRender: "prescriptionCount"
          }
        },
        {
          title: "秘方数",
          key: "mifangCount",
          dataIndex: "mifangCount",
          // ellipsis: true,
          width: "6%",
          slots: {
            customRender: "mifangCount"
          }
        },
        {
          title: "经方数",
          key: "jingfangCount",
          dataIndex: "jingfangCount",
          // ellipsis: true,
          width: "6%",
          slots: {
            customRender: "jingfangCount"
          }
        },
        {
          title: "消费（元）",
          key: "payAmount",
          dataIndex: "payAmount",
          // ellipsis: true,
          width: "8%",
          slots: {
            customRender: "payAmount"
          }
        },
        {
          title: "问诊",
          key: "consultationResult",
          dataIndex: "consultationResult",
          // ellipsis: true,
          width: "20%",
          slots: {
            customRender: "consultationResult"
          }
        },
        {
          title: "脉诊",
          key: "mzyYunmai",
          dataIndex: "mzyYunmai",
          // ellipsis: true,
          width: "10%",
          slots: {
            customRender: "mzyYunmai"
          }
        },
        {
          title: "日期",
          key: "date",
          dataIndex: "date",
          // ellipsis: true,
          width: "8%",
          slots: {
            customRender: "date"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "10%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRows = selectedRows;
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        }
      },
      editableData: reactive({}),
      selectedRows: {}
      // doExcel: doExcel
    };
  },
  created() { },
  mounted() {
    this.search("");
  },
  activated() {
    if (localStorage.getItem("refrashProject") == "true") {
      // this.search(1);
    }
    localStorage.setItem("refrashProject", false);
  },
  methods: {

    closeModal(value) {
      this[value] = false;
    },
    getConsultation(record) {
      this.visible = true
      this.selectedRow = { ...record }
    },

    resetForm() {
      this.inputs = {
        name: undefined,
        gender: undefined,
        isStandard: undefined,
      }
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      this.data = this.data.filter(function (item) {
        return item.id !== id;
      });
    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    async save(id) {
      let _data = this.data;
      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        delete this.editableData[id];
        return;
      }
      let params = {
        id: this.editableData[id].id,
        name: this.editableData[id].name,
        nameInitial: this.editableData[id].nameInitial,
        gender: this.editableData[id].gender,
      }
      console.log(params);
      let res = await Update(params)
      if (res.errCode == 0) {
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        delete this.editableData[id];
      }
    },
    cancel(id) {
      delete this.editableData[id];
    },
    tabsName(tabName) {
      this.$store.commit("changePage", { name: tabName.name, query: tabName.query });
    },
    async search(pagination) {
      let _this = this;
      let startDate = typeof this.date[0] == "string" ? this.date[0] : this.date[0].format("YYYY-MM-DD")
      let endDate = typeof this.date[1] == "string" ? this.date[1] : this.date[1].format("YYYY-MM-DD")

      console.log(this.date[0]);
      console.log();

      let params = {
        name: this.inputs.name,
        nameInitial: this.inputs.nameInitial,
        gender: this.inputs.gender,
        isStandard: this.inputs.isStandard,
        pageSize: this.$store.state.tableSize,
        // date: "2024-6-6",
        startDate,
        endDate,
      };
      if (pagination == "") {
        params.current = 1
      } else {
        params.current = pagination.current
        this.current = pagination.current;
      }

      let res = await Search(params)
      if (res.errCode == 0) {
        if (pagination == "") {
          // this.$message.success("查询成功", function () { });
          this.current = 1;
        }
        this.total = res.data.total;
        this.data = res.data.records;
        this.customerCount = res.data.customerCount
        this.medicalRecordCount = res.data.medicalRecordCount
        this.totalPrescriptionCount = res.data.totalPrescriptionCount
        this.totalMifangCount = res.data.totalMifangCount
        this.totalJingfangCount = res.data.totalJingfangCount
        this.totalPayAmount = res.data.totalPayAmount
        this.consumptionCount = res.data.consumptionCount
        this.consultationCount = res.data.consultationCount
        this.mzyCount = res.data.mzyCount
      } else {
        this.$message.error("查询失败", function () { });
      }
    },
    async changePage(pagination) {
      let _this = this;
      this.current = pagination.current;
      this.editableData = {}
      let params = {
        name: this.inputs.name,
        nameInitial: this.inputs.nameInitial,
        gender: this.inputs.gender,
        isStandard: this.inputs.isStandard,
        current: this.current,
        pageSize: this.$store.state.tableSize,
        includeMifang: 1,
        includeJingfang: 1,
      };

      let res = await Search(params)
      if (res.errCode == 0) {
        this.total = res.data.total;
        this.data = res.data.records
      } else {
        this.$message.error("查询失败", function () { });
      }
    },
    async changeIsStandard(record) {
      let res = await Update({ id: record.id, isStandard: !record.isStandard })
      if (res.errCode == 0) {
        record.isStandard = !record.isStandard;
      } else {
        this.$message.error("操作失败", function () { });
      }
    },

  }
};
</script>